import SkeletonLoader from 'components/SkeletonLoader';
import { Suspense } from 'react';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks/useAppSelector';
import { updateUserLocationState } from 'redux/location/actions';
import PrivateAdminDashboard from 'routers/PrivateAdminRoute';
import PrivateFacilityRoute from 'routers/PrivateFacilityRoute';
import PublicRoute from 'routers/PublicRoute';
import { Loadable } from 'utils/ComponentLoader';
import { ILocation, getUserLocation } from 'utils/helpers';
import './index.css';
import RedirectComponent from 'routers/RedirectComponent';

const AdminLoginPage = Loadable(() => import('pages/admin/authentication/LoginPage'));
const LandingPage = Loadable(() => import('pages/landing/LandingPage'));
const LoginPage = Loadable(() => import('pages/login/LoginPage'));
const SignUpPage = Loadable(() => import('pages/signUp/SignUpPage'));
const ForgotPasswordPage = Loadable(() => import('pages/forgotPassword/ForgotPassword'));
const ResetPasswordPage = Loadable(() => import('pages/forgotPassword/ResetPassword'));
const VerifyTokenPage = Loadable(() => import('pages/verifyToken/VerifyTokenPage'));
const ProceduresPage = Loadable(
  () => import('pages/admin/configurations/procedures/ProceduresPage'),
);
const FacilitiesPage = Loadable(() => import('pages/admin/facilities/FacilitiesPage'));
const PatientsPage = Loadable(() => import('pages/admin/patients/PatientsPage'));
const QualitiesPage = Loadable(() => import('pages/admin/configurations/qualities/QualitiesPage'));
const ConditionsPage = Loadable(
  () => import('pages/admin/configurations/conditions/ConditionsPage'),
);
const InsurancePage = Loadable(() => import('pages/admin/configurations/insurance/InsurancePage'));
const BlogPostsPage = Loadable(() => import('pages/admin/configurations/blogs/BlogPostsPage'));
const AwardsPage = Loadable(() => import('pages/admin/configurations/awards/AwardsPage'));
const EmergencyPage = Loadable(() => import('pages/admin/emergency/EmergencyPage'));
const ProvidersPage = Loadable(() => import('pages/admin/providers/ProvidersPage'));
const ServicesPage = Loadable(() => import('pages/admin/services/ServicesPage'));
const SpecialtiesPage = Loadable(
  () => import('pages/admin/configurations/specialties/SpecialtiesPage'),
);
const FacilityTypesPage = Loadable(
  () => import('pages/admin/configurations/facility-types/FacilityTypesPage'),
);
const PracticeAreasPage = Loadable(
  () => import('pages/admin/configurations/practiceAreas/PracticeAreasPage'),
);
const CovidVaccinePage = Loadable(() => import('pages/covid/CovidVaccinePage'));
const ProviderProfileHome = Loadable(
  () => import('pages/profiles/providerProfile/providerProfileHome'),
);
const ProviderProfile = Loadable(() => import('pages/profiles/providerProfile/ProviderProfile'));
const ChangePasswordPage = Loadable(
  () => import('pages/profiles/providerProfile/ChangePasswordPage'),
);
const AccountSettings = Loadable(() => import('pages/profiles/providerProfile/AccountSettings'));
const FacilityProfilePage = Loadable(() => import('pages/facility-details/FacilityProfilePage'));
const FacilityDashboardPage = Loadable(
  () => import('pages/facility-details/FacilityDashboardPage'),
);
const SearchResult = Loadable(() => import('pages/searchResult/SearchResultPage'));
const BlogPage = Loadable(() => import('pages/blog/BlogPage'));
const BlogDetailsPage = Loadable(() => import('pages/blogDetail/BlogDetailsPage'));
const AddBlogPost = Loadable(() => import('pages/admin/configurations/blogs/AddBlogPost'));
const FacilityTypesDirectory = Loadable(
  () => import('pages/facilityTypesDirecotry/FacilityTypesDirectory'),
);
const FacilityServiceDirectory = Loadable(
  () => import('pages/facilityServiceDirectory/FacilityServiceDirectory'),
);
const UserDashboardPage = Loadable(() => import('pages/user-details/userDashboardPage'));
const UserProfile = Loadable(() => import('pages/user-details/userProfile'));

const SpecialtyDirectory = Loadable(() => import('pages/specialtyDirectory/SpecialtyDirectory'));
const ConditionsDirectory = Loadable(() => import('pages/conditionsDirectory/ConditionsDirectory'));
const ProceduresDirectory = Loadable(() => import('pages/proceduresDirectory/ProceduresDirectory'));
const AboutUsPage = Loadable(() => import('pages/aboutUs/AboutUsPage'));
const EmergencyContactsPage = Loadable(
  () => import('pages/emergencyContacts/EmergencyContactsPage'),
);

const PrivacyPolicy = Loadable(() => import('pages/privacyPolicy/PrivacyPolicy'));
const FAQPage = Loadable(() => import('pages/faq/FAQ'));
const Terms = Loadable(() => import('pages/terms/Terms'));
const ContactUs = Loadable(() => import('pages/contactUs/ContactUs'));

function App() {
  const dispatch = useDispatch();
  const userLocation = useAppSelector((state) => state.location);

  if (!userLocation || !userLocation?.latitude) {
    getUserLocation()
      .then((cord: ILocation) => {
        dispatch(
          updateUserLocationState({
            latitude: cord.coords.latitude,
            longitude: cord.coords.longitude,
          }),
        );
      })
      .catch((err) => {
        console.log('error', err);
      });
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={'/'}
          element={
            <Suspense fallback={<SkeletonLoader />}>
              <LandingPage />
            </Suspense>
          }
        />
        <Route
          path={'/covid-19'}
          element={
            <Suspense fallback={<SkeletonLoader />}>
              <CovidVaccinePage />
            </Suspense>
          }
        />
        <Route
          path={'/emergency-contacts'}
          element={
            <Suspense fallback={<SkeletonLoader />}>
              <EmergencyContactsPage />
            </Suspense>
          }
        />
        <Route
          path={'change-password'}
          element={
            <Suspense fallback={<SkeletonLoader />}>
              <ChangePasswordPage />
            </Suspense>
          }
        />
        <Route
          path={'/facilities/:id'}
          element={
            <Suspense fallback={<SkeletonLoader />}>
              <FacilityProfilePage />
            </Suspense>
          }
        />
        <Route
          path="/facilityDetails/:id"
          element={<RedirectComponent to="/facilities/" paramKey="id" />}
        />
        <Route
          path={'/facility/dashboard'}
          element={
            <PrivateFacilityRoute>
              <Suspense fallback={<SkeletonLoader />}>
                <FacilityDashboardPage />
              </Suspense>
            </PrivateFacilityRoute>
          }
        />
        <Route
          path={'/providers/:id'}
          element={
            <Suspense fallback={<SkeletonLoader />}>
              <ProviderProfile />
            </Suspense>
          }
        />
        <Route
          path={'/user/dashboard'}
          element={
            <Suspense fallback={<SkeletonLoader />}>
              <UserDashboardPage />
            </Suspense>
          }
        />
        <Route
          path={'/user/profile'}
          element={
            <Suspense fallback={<SkeletonLoader />}>
              <UserProfile />
            </Suspense>
          }
        />
        <Route
          path={'/admin-login'}
          element={
            <Suspense fallback={<SkeletonLoader />}>
              <PublicRoute>
                <AdminLoginPage />
              </PublicRoute>
            </Suspense>
          }
        />
        <Route path="/provider/*">
          {/* all provider dashboard routes here */}
          <Route
            path={'dashboard'}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <ProviderProfileHome />
              </Suspense>
            }
          />

          {/* <Route
            path={'complete-profile/basic-info'}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <ProviderCompleteBasicInfo />
              </Suspense>
            }
          />
          <Route
            path={'complete-profile/work-info'}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <ProviderCompleteWorkInfo />
              </Suspense>
            }
          />
          <Route
            path={'account-settings'}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <AccountSettings />
              </Suspense>
            }
          /> */}
        </Route>
        <Route
          path={'/login'}
          element={
            <Suspense fallback={<SkeletonLoader />}>
              <PublicRoute>
                <LoginPage />
              </PublicRoute>
            </Suspense>
          }
        />

        <Route
          path={'/signup'}
          element={
            <Suspense fallback={<SkeletonLoader />}>
              <PublicRoute>
                <SignUpPage />
              </PublicRoute>
            </Suspense>
          }
        />

        <Route
          path={'/forgot-password'}
          element={
            <Suspense fallback={<SkeletonLoader />}>
              <PublicRoute>
                <ForgotPasswordPage />
              </PublicRoute>
            </Suspense>
          }
        />
        <Route
          path={'/reset-password'}
          element={
            <Suspense fallback={<SkeletonLoader />}>
              <PublicRoute>
                <ResetPasswordPage />
              </PublicRoute>
            </Suspense>
          }
        />
        <Route path="/admin/*" element={<PrivateAdminDashboard />}>
          {/* all dashboard routes here */}
          <Route
            path={'procedures'}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <ProceduresPage />
              </Suspense>
            }
          />
          <Route
            path={'users'}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <PatientsPage />
              </Suspense>
            }
          />
          <Route
            path={'qualities'}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <QualitiesPage />
              </Suspense>
            }
          />
          <Route
            path={'conditions'}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <ConditionsPage />
              </Suspense>
            }
          />
          <Route
            path={'practice-areas'}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <PracticeAreasPage />
              </Suspense>
            }
          />
          <Route
            path={'blogs'}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <BlogPostsPage />
              </Suspense>
            }
          />

          <Route
            path={'edit-blog/:id'}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <AddBlogPost />
              </Suspense>
            }
          />

          <Route
            path={'add-blog'}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <AddBlogPost />
              </Suspense>
            }
          />

          <Route
            path={'awards'}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <AwardsPage />
              </Suspense>
            }
          />
          <Route
            path={'insurance'}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <InsurancePage />
              </Suspense>
            }
          />
          <Route
            path={'facilities'}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <FacilitiesPage />
              </Suspense>
            }
          />
          <Route
            path={'emergency'}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <EmergencyPage />
              </Suspense>
            }
          />
          <Route
            path={'dashboard'}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <>hello</>
              </Suspense>
            }
          />
          <Route
            path="providers"
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <ProvidersPage />
              </Suspense>
            }
          />
          <Route
            path="services"
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <ServicesPage />
              </Suspense>
            }
          />
          <Route
            path="facility-types"
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <FacilityTypesPage />
              </Suspense>
            }
          />
          <Route
            path="specialties"
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <SpecialtiesPage />
              </Suspense>
            }
          />
        </Route>

        <Route
          path={'/verify-token'}
          element={
            <Suspense fallback={<SkeletonLoader />}>
              <PublicRoute>
                <VerifyTokenPage />
              </PublicRoute>
            </Suspense>
          }
        />

        <Route
          path={'/search-result'}
          element={
            <Suspense fallback={<SkeletonLoader />}>
              <SearchResult />
            </Suspense>
          }
        />

        <Route
          path={'/blog'}
          element={
            <Suspense fallback={<SkeletonLoader />}>
              <BlogPage />
            </Suspense>
          }
        />

        <Route
          path={'/blog/:id'}
          element={
            <Suspense fallback={<SkeletonLoader />}>
              <BlogDetailsPage />
            </Suspense>
          }
        />

        <Route
          path={'/facility-types-directory'}
          element={
            <Suspense fallback={<SkeletonLoader />}>
              <FacilityTypesDirectory />
            </Suspense>
          }
        />

        <Route
          path={'/facility-service-directory'}
          element={
            <Suspense fallback={<SkeletonLoader />}>
              <FacilityServiceDirectory />
            </Suspense>
          }
        />

        <Route
          path={'/specialty-directory'}
          element={
            <Suspense fallback={<SkeletonLoader />}>
              <SpecialtyDirectory />
            </Suspense>
          }
        />

        <Route
          path={'/conditions-directory'}
          element={
            <Suspense fallback={<SkeletonLoader />}>
              <ConditionsDirectory />
            </Suspense>
          }
        />

        <Route
          path={'/procedures-directory'}
          element={
            <Suspense fallback={<SkeletonLoader />}>
              <ProceduresDirectory />
            </Suspense>
          }
        />

        <Route
          path={'/about-us'}
          element={
            <Suspense fallback={<SkeletonLoader />}>
              <AboutUsPage />
            </Suspense>
          }
        />

        <Route
          path={'/privacyPolicy'}
          element={
            <Suspense fallback={<SkeletonLoader />}>
              <PrivacyPolicy />
            </Suspense>
          }
        />

        <Route
          path={'/terms'}
          element={
            <Suspense fallback={<SkeletonLoader />}>
              <Terms />
            </Suspense>
          }
        />

        <Route
          path={'/contactUs'}
          element={
            <Suspense fallback={<SkeletonLoader />}>
              <ContactUs />
            </Suspense>
          }
        />

        <Route
          path={'/faq'}
          element={
            <Suspense fallback={<SkeletonLoader />}>
              <FAQPage />
            </Suspense>
          }
        />
      </Routes>
      <NotificationContainer />
    </BrowserRouter>
  );
}

export default App;
