import { Navigate } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks/useAppSelector';

const PrivateFacilityRoute = ({ children }) => {
  const accessToken = useAppSelector((state) => state.user.currentUser?.accessToken);
  const userType = useAppSelector((state) => state.user.currentUser?.userType);
  const isFacilityAdmin = userType === 'FACILITY_ADMIN';
  const auth = !!accessToken;
  return !auth || !isFacilityAdmin ? <Navigate to={'/'} /> : children;
};

export default PrivateFacilityRoute;
