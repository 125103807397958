// @ts-ignore

import { OpenDay } from 'generated';
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import moment from 'moment';
import { NotificationManager } from 'react-notifications';
import { Breakpoints } from './interfaces';

export const findIndexInArray = (originalArray, objectToFInd, key, key2?: string | undefined) => {
  return originalArray.findIndex((item) => {
    if (key2) return item[key][key2] === objectToFInd[key][key2];
    else return item[key] === objectToFInd[key];
  });
};

export const toCamelCase = (str: string) => {
  return str
    .toLowerCase() // Step 1: Convert the entire string to lowercase
    .split(' ') // Step 2: Split the string into words
    .map((word, index) => {
      // Step 3: Capitalize the first letter of each word except the first word
      if (index === 0) {
        return word;
      }
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(''); // Step 4: Concatenate the words back together
};

export const transformKeysToCamelCase = (key) => {
  return key
    .toLowerCase()
    .replace(/\s(.)/g, (match, letter) => letter.toUpperCase())
    .replace(/\*/g, '')
    .replace(/\s/g, '');
};

export const returnUpdatedList = (newObj, oldList, key = 'id', key2 = '') => {
  const indexOfLocal = findIndexInArray(oldList, newObj, key, key2);
  if (indexOfLocal !== -1) oldList[indexOfLocal] = newObj;
  return oldList;
};

export const enumToOptions = (enumList) => {
  return Object.keys(enumList)
    .sort((a, b) => a.localeCompare(b))
    .reduce((acc: any, cur) => {
      acc.push({
        label: enumList[cur],
        value: enumList[cur],
      });
      return acc;
    }, []);
};

export const removeDuplicates = (myArr: any[], prop: any) => {
  return myArr.filter((obj, pos, arr) => {
    return arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos;
  });
};

//rounds up a number(value) to x decimal places
// it returns a whole number when value is whole
export const roundUp = (value: number, x: number) => {
  const multiplier = Math.pow(10, x || 0);
  return Math.round(value * multiplier) / multiplier;
};

// function to add st, nd, rd, th to positions
export const postion = (number: number) => {
  const lastDigit = number % 10,
    k = number % 100;

  if (lastDigit === 1 && k !== 11) {
    return number + 'st';
  } else if (lastDigit === 2 && k !== 12) {
    return number + 'nd';
  } else if (lastDigit === 3 && k !== 13) {
    return number + 'rd';
  } else {
    return number + 'th';
  }
};

export const getUrlParam = (param: string) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
};

export const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

export function waitForDomElement(selector) {
  return new Promise((resolve) => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }
    const observer = new MutationObserver((mutations) => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        observer.disconnect();
      }
    });
    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
  // Usage
  // const help_button = await waitForDomElement('#help-btn');
}

export const isEmpty = (value: any) => {
  return value == null || (typeof value === 'string' && value.trim().length === 0);
};

export const calculateDatePercentage = (startDate: Date, endDate: Date): number => {
  const now = new Date().getTime();
  const start = startDate.getTime();
  const end = endDate.getTime();
  const range = end - start;
  const val = Math.ceil((100 * (now - start)) / range);
  return val > 100 ? 100 : 0 > val ? 0 : val;
};

export const breakpoints: Breakpoints = {
  base: 480,
  md: 768,
  lg: 992,
  xl: 1280,
  xxl: 1536,
};

export const formatDate = (dateType: Date | string) => {
  const date = moment(dateType);
  const now = moment();
  if (now.diff(date, 'days') < 7) {
    return date.fromNow(); // Output: a week ago
  } else if (now.diff(date, 'months') < 1) {
    return date.fromNow(); // Output: 1 week ago
  } else {
    const formattedDate = date.format('h:mm A, MMMM D');
    return formattedDate;
  }
};

export const renderNewColor = () => {
  const colorArray = [
    '#f26522',
    '#02b176',
    '#000000',
    '#7e7e7e',
    '#DD9787',
    '#3DCCC7',
    '#E75A7C',
    '#632A50',
    '#DE6449',
    '#745296',
  ];
  const chosenColor = Math.floor(Math.random() * colorArray.length);
  return colorArray[chosenColor];
};
export const scrollToSection = (id) => {
  const section = document.getElementById(id);
  section.scrollIntoView({ behavior: 'smooth' });
};

export const Notify = NotificationManager;

export const userTypeHelper = (userType) => {
  switch (userType) {
    case 'FACILITY_ADMIN':
      return 'Facility';
    case 'PROVIDER':
      return 'Provider';
    case 'REVIEWER':
      return 'User';
    default:
      return 'User';
  }
};

export const checkUserType = (user) => {
  switch (user.userType) {
    case 'FACILITY_ADMIN':
      return '/facility/dashboard';
    case 'PROVIDER':
      return '/provider/dashboard';
    case 'REVIEWER':
      return '/user/dashboard';
    default:
      return '/user/dashboard';
  }
};

const isOpenWithinTimeRange = (openingHoursData: OpenDay) => {
  const format = 'HH:mm:ss';
  const currentTime = moment().toISOString();
  const openTime = moment(openingHoursData.open, format);
  const closeTime = moment(openingHoursData.close, format).add(1, 'day');
  const currentDay = moment().format('dddd').toUpperCase();

  if (openingHoursData?.day === currentDay) {
    const isOpenNow = openingHoursData.isAlwaysOpen
      ? true
      : openingHoursData.isClosed
      ? false
      : moment(currentTime, format).isBetween(openTime, closeTime);

    return {
      isOpen: isOpenNow,
      isAlwayOpen: openingHoursData.isAlwaysOpen,
      openingTime: moment(openingHoursData.open).format('HH:mmA'),
      closingTime: moment(openingHoursData.close).format('HH:mmA'),
    };
  } else {
    return null;
  }
};

export const checkOpeningHours = (openingHoursArray: OpenDay[]) => {
  return openingHoursArray
    ?.map(isOpenWithinTimeRange)
    .filter((openingHoursData) => openingHoursData !== null);
};

export const userTypeRating = (data) => {
  if (data) {
    return Number(data?.averageRating).toFixed(1);
  }
  return data?.averageRating;
};

export enum locationEnum {
  latitude = 'latitude',
  longitude = 'longitude',
}
export const latAndLongCheck = (value: number, location: locationEnum): boolean => {
  switch (location) {
    case 'latitude':
      return typeof value === 'number' && !isNaN(value) && value >= -90 && value <= 90;
    case 'longitude':
      return typeof value === 'number' && !isNaN(value) && value >= -180 && value <= 180;
    default:
      return true;
  }
};

// Format phone number

export const getFormattedPhoneNumber = (phoneNumber?: string) => {
  if (!phoneNumber) {
    return '';
  }

  // Remove spaces between numbers
  const sanitized = phoneNumber.replace(/\s+/g, '');

  const hasCountryCode = sanitized.startsWith('+');

  // Add +234 to numbers without country code
  const withCountryCode = !hasCountryCode ? `+234${sanitized}` : sanitized;

  if (isValidPhoneNumber(withCountryCode)) {
    return parsePhoneNumber(withCountryCode).formatInternational();
  }

  return withCountryCode;
};

export const states = [
  {
    state: 'Adamawa',
    alias: 'adamawa',
    lgas: [
      'Demsa',
      'Fufure',
      'Ganye',
      'Gayuk',
      'Gombi',
      'Grie',
      'Hong',
      'Jada',
      'Larmurde',
      'Madagali',
      'Maiha',
      'Mayo Belwa',
      'Michika',
      'Mubi North',
      'Mubi South',
      'Numan',
      'Shelleng',
      'Song',
      'Toungo',
      'Yola North',
      'Yola South',
    ],
  },
  {
    state: 'Akwa Ibom',
    alias: 'akwa_ibom',
    lgas: [
      'Abak',
      'Eastern Obolo',
      'Eket',
      'Esit Eket',
      'Essien Udim',
      'Etim Ekpo',
      'Etinan',
      'Ibeno',
      'Ibesikpo Asutan',
      'Ibiono-Ibom',
      'Ikot Abasi',
      'Ika',
      'Ikono',
      'Ikot Ekpene',
      'Ini',
      'Mkpat-Enin',
      'Itu',
      'Mbo',
      'Nsit-Atai',
      'Nsit-Ibom',
      'Nsit-Ubium',
      'Obot Akara',
      'Okobo',
      'Onna',
      'Oron',
      'Udung-Uko',
      'Ukanafun',
      'Oruk Anam',
      'Uruan',
      'Urue-Offong/Oruko',
      'Uyo',
    ],
  },
  {
    state: 'Anambra',
    alias: 'anambra',
    lgas: [
      'Aguata',
      'Anambra East',
      'Anaocha',
      'Awka North',
      'Anambra West',
      'Awka South',
      'Ayamelum',
      'Dunukofia',
      'Ekwusigo',
      'Idemili North',
      'Idemili South',
      'Ihiala',
      'Njikoka',
      'Nnewi North',
      'Nnewi South',
      'Ogbaru',
      'Onitsha North',
      'Onitsha South',
      'Orumba North',
      'Orumba South',
      'Oyi',
    ],
  },
  {
    state: 'Ogun',
    alias: 'ogun',
    lgas: [
      'Abeokuta North',
      'Abeokuta South',
      'Ado-Odo/Ota',
      'Egbado North',
      'Ewekoro',
      'Egbado South',
      'Ijebu North',
      'Ijebu East',
      'Ifo',
      'Ijebu Ode',
      'Ijebu North East',
      'Imeko Afon',
      'Ikenne',
      'Ipokia',
      'Odeda',
      'Obafemi Owode',
      'Odogbolu',
      'Remo North',
      'Ogun Waterside',
      'Shagamu',
    ],
  },
  {
    state: 'Ondo',
    alias: 'ondo',
    lgas: [
      'Akoko North-East',
      'Akoko North-West',
      'Akoko South-West',
      'Akoko South-East',
      'Akure North',
      'Akure South',
      'Ese Odo',
      'Idanre',
      'Ifedore',
      'Ilaje',
      'Irele',
      'Ile Oluji/Okeigbo',
      'Odigbo',
      'Okitipupa',
      'Ondo West',
      'Ose',
      'Ondo East',
      'Owo',
    ],
  },
  {
    state: 'Rivers',
    alias: 'rivers',
    lgas: [
      'Abua/Odual',
      'Ahoada East',
      'Ahoada West',
      'Andoni',
      'Akuku-Toru',
      'Asari-Toru',
      'Bonny',
      'Degema',
      'Emuoha',
      'Eleme',
      'Ikwerre',
      'Etche',
      'Gokana',
      'Khana',
      'Obio/Akpor',
      'Ogba/Egbema/Ndoni',
      'Ogu/Bolo',
      'Okrika',
      'Omuma',
      'Opobo/Nkoro',
      'Oyigbo',
      'Port Harcourt',
      'Tai',
    ],
  },
  {
    state: 'Bauchi',
    alias: 'bauchi',
    lgas: [
      'Alkaleri',
      'Bauchi',
      'Bogoro',
      'Damban',
      'Darazo',
      'Dass',
      'Gamawa',
      'Ganjuwa',
      'Giade',
      'Itas/Gadau',
      "Jama'are",
      'Katagum',
      'Kirfi',
      'Misau',
      'Ningi',
      'Shira',
      'Tafawa Balewa',
      'Toro',
      'Warji',
      'Zaki',
    ],
  },
  {
    state: 'Benue',
    alias: 'benue',
    lgas: [
      'Agatu',
      'Apa',
      'Ado',
      'Buruku',
      'Gboko',
      'Guma',
      'Gwer East',
      'Gwer West',
      'Katsina-Ala',
      'Konshisha',
      'Kwande',
      'Logo',
      'Makurdi',
      'Obi',
      'Ogbadibo',
      'Ohimini',
      'Oju',
      'Okpokwu',
      'Oturkpo',
      'Tarka',
      'Ukum',
      'Ushongo',
      'Vandeikya',
    ],
  },
  {
    state: 'Borno',
    alias: 'borno',
    lgas: [
      'Abadam',
      'Askira/Uba',
      'Bama',
      'Bayo',
      'Biu',
      'Chibok',
      'Damboa',
      'Dikwa',
      'Guzamala',
      'Gubio',
      'Hawul',
      'Gwoza',
      'Jere',
      'Kaga',
      'Kala/Balge',
      'Konduga',
      'Kukawa',
      'Kwaya Kusar',
      'Mafa',
      'Magumeri',
      'Maiduguri',
      'Mobbar',
      'Marte',
      'Monguno',
      'Ngala',
      'Nganzai',
      'Shani',
    ],
  },
  {
    state: 'Bayelsa',
    alias: 'bayelsa',
    lgas: [
      'Brass',
      'Ekeremor',
      'Kolokuma/Opokuma',
      'Nembe',
      'Ogbia',
      'Sagbama',
      'Southern Ijaw',
      'Yenagoa',
    ],
  },
  {
    state: 'Cross River',
    alias: 'cross_river',
    lgas: [
      'Abi',
      'Akamkpa',
      'Akpabuyo',
      'Bakassi',
      'Bekwarra',
      'Biase',
      'Boki',
      'Calabar Municipal',
      'Calabar South',
      'Etung',
      'Ikom',
      'Obanliku',
      'Obubra',
      'Obudu',
      'Odukpani',
      'Ogoja',
      'Yakuur',
      'Yala',
    ],
  },
  {
    state: 'Delta',
    alias: 'delta',
    lgas: [
      'Aniocha North',
      'Aniocha South',
      'Bomadi',
      'Burutu',
      'Ethiope West',
      'Ethiope East',
      'Ika North East',
      'Ika South',
      'Isoko North',
      'Isoko South',
      'Ndokwa East',
      'Ndokwa West',
      'Okpe',
      'Oshimili North',
      'Oshimili South',
      'Patani',
      'Sapele',
      'Udu',
      'Ughelli North',
      'Ukwuani',
      'Ughelli South',
      'Uvwie',
      'Warri North',
      'Warri South',
      'Warri South West',
    ],
  },
  {
    state: 'Ebonyi',
    alias: 'ebonyi',
    lgas: [
      'Abakaliki',
      'Afikpo North',
      'Ebonyi',
      'Afikpo South',
      'Ezza North',
      'Ikwo',
      'Ezza South',
      'Ivo',
      'Ishielu',
      'Izzi',
      'Ohaozara',
      'Ohaukwu',
      'Onicha',
    ],
  },
  {
    state: 'Edo',
    alias: 'edo',
    lgas: [
      'Akoko-Edo',
      'Egor',
      'Esan Central',
      'Esan North-East',
      'Esan South-East',
      'Esan West',
      'Etsako Central',
      'Etsako East',
      'Etsako West',
      'Igueben',
      'Ikpoba Okha',
      'Orhionmwon',
      'Oredo',
      'Ovia North-East',
      'Ovia South-West',
      'Owan East',
      'Owan West',
      'Uhunmwonde',
    ],
  },
  {
    state: 'Ekiti',
    alias: 'ekiti',
    lgas: [
      'Ado Ekiti',
      'Efon',
      'Ekiti East',
      'Ekiti South-West',
      'Ekiti West',
      'Emure',
      'Gbonyin',
      'Ido Osi',
      'Ijero',
      'Ikere',
      'Ilejemeje',
      'Irepodun/Ifelodun',
      'Ikole',
      'Ise/Orun',
      'Moba',
      'Oye',
    ],
  },
  {
    state: 'Enugu',
    alias: 'enugu',
    lgas: [
      'Awgu',
      'Aninri',
      'Enugu East',
      'Enugu North',
      'Ezeagu',
      'Enugu South',
      'Igbo Etiti',
      'Igbo Eze North',
      'Igbo Eze South',
      'Isi Uzo',
      'Nkanu East',
      'Nkanu West',
      'Nsukka',
      'Udenu',
      'Oji River',
      'Uzo Uwani',
      'Udi',
    ],
  },
  {
    state: 'Federal Capital Territory',
    alias: 'abuja',
    lgas: ['Abaji', 'Bwari', 'Gwagwalada', 'Kuje', 'Kwali', 'Municipal Area Council'],
  },
  {
    state: 'Gombe',
    alias: 'gombe',
    lgas: [
      'Akko',
      'Balanga',
      'Billiri',
      'Dukku',
      'Funakaye',
      'Gombe',
      'Kaltungo',
      'Kwami',
      'Nafada',
      'Shongom',
      'Yamaltu/Deba',
    ],
  },
  {
    state: 'Jigawa',
    alias: 'jigawa',
    lgas: [
      'Auyo',
      'Babura',
      'Buji',
      'Biriniwa',
      'Birnin Kudu',
      'Dutse',
      'Gagarawa',
      'Garki',
      'Gumel',
      'Guri',
      'Gwaram',
      'Gwiwa',
      'Hadejia',
      'Jahun',
      'Kafin Hausa',
      'Kazaure',
      'Kiri Kasama',
      'Kiyawa',
      'Kaugama',
      'Maigatari',
      'Malam Madori',
      'Miga',
      'Sule Tankarkar',
      'Roni',
      'Ringim',
      'Yankwashi',
      'Taura',
    ],
  },
  {
    state: 'Oyo',
    alias: 'oyo',
    lgas: [
      'Afijio',
      'Akinyele',
      'Atiba',
      'Atisbo',
      'Egbeda',
      'Ibadan North',
      'Ibadan North-East',
      'Ibadan North-West',
      'Ibadan South-East',
      'Ibarapa Central',
      'Ibadan South-West',
      'Ibarapa East',
      'Ido',
      'Ibarapa North',
      'Irepo',
      'Iseyin',
      'Itesiwaju',
      'Iwajowa',
      'Kajola',
      'Lagelu',
      'Ogbomosho North',
      'Ogbomosho South',
      'Ogo Oluwa',
      'Olorunsogo',
      'Oluyole',
      'Ona Ara',
      'Orelope',
      'Ori Ire',
      'Oyo',
      'Oyo East',
      'Saki East',
      'Saki West',
      'Surulere Oyo State',
    ],
  },
  {
    state: 'Imo',
    alias: 'imo',
    lgas: [
      'Aboh Mbaise',
      'Ahiazu Mbaise',
      'Ehime Mbano',
      'Ezinihitte',
      'Ideato North',
      'Ideato South',
      'Ihitte/Uboma',
      'Ikeduru',
      'Isiala Mbano',
      'Mbaitoli',
      'Isu',
      'Ngor Okpala',
      'Njaba',
      'Nkwerre',
      'Nwangele',
      'Obowo',
      'Oguta',
      'Ohaji/Egbema',
      'Okigwe',
      'Orlu',
      'Orsu',
      'Oru East',
      'Oru West',
      'Owerri Municipal',
      'Owerri North',
      'Unuimo',
      'Owerri West',
    ],
  },
  {
    state: 'Kaduna',
    alias: 'kaduna',
    lgas: [
      'Birnin Gwari',
      'Chikun',
      'Giwa',
      'Ikara',
      'Igabi',
      'Jaba',
      "Jema'a",
      'Kachia',
      'Kaduna North',
      'Kaduna South',
      'Kagarko',
      'Kajuru',
      'Kaura',
      'Kauru',
      'Kubau',
      'Kudan',
      'Lere',
      'Makarfi',
      'Sabon Gari',
      'Sanga',
      'Soba',
      'Zangon Kataf',
      'Zaria',
    ],
  },
  {
    state: 'Kebbi',
    alias: 'kebbi',
    lgas: [
      'Aleiro',
      'Argungu',
      'Arewa Dandi',
      'Augie',
      'Bagudo',
      'Birnin Kebbi',
      'Bunza',
      'Dandi',
      'Fakai',
      'Gwandu',
      'Jega',
      'Kalgo',
      'Koko/Besse',
      'Maiyama',
      'Ngaski',
      'Shanga',
      'Suru',
      'Sakaba',
      'Wasagu/Danko',
      'Yauri',
      'Zuru',
    ],
  },
  {
    state: 'Kano',
    alias: 'kano',
    lgas: [
      'Ajingi',
      'Albasu',
      'Bagwai',
      'Bebeji',
      'Bichi',
      'Bunkure',
      'Dala',
      'Dambatta',
      'Dawakin Kudu',
      'Dawakin Tofa',
      'Doguwa',
      'Fagge',
      'Gabasawa',
      'Garko',
      'Garun Mallam',
      'Gezawa',
      'Gaya',
      'Gwale',
      'Gwarzo',
      'Kabo',
      'Kano Municipal',
      'Karaye',
      'Kibiya',
      'Kiru',
      'Kumbotso',
      'Kunchi',
      'Kura',
      'Madobi',
      'Makoda',
      'Minjibir',
      'Nasarawa',
      'Rano',
      'Rimin Gado',
      'Rogo',
      'Shanono',
      'Takai',
      'Sumaila',
      'Tarauni',
      'Tofa',
      'Tsanyawa',
      'Tudun Wada',
      'Ungogo',
      'Warawa',
      'Wudil',
    ],
  },
  {
    state: 'Kogi',
    alias: 'kogi',
    lgas: [
      'Ajaokuta',
      'Adavi',
      'Ankpa',
      'Bassa',
      'Dekina',
      'Ibaji',
      'Idah',
      'Igalamela Odolu',
      'Ijumu',
      'Kogi',
      'Kabba/Bunu',
      'Lokoja',
      'Ofu',
      'Mopa Muro',
      'Ogori/Magongo',
      'Okehi',
      'Okene',
      'Olamaboro',
      'Omala',
      'Yagba East',
      'Yagba West',
    ],
  },
  {
    state: 'Osun',
    alias: 'osun',
    lgas: [
      'Aiyedire',
      'Atakunmosa West',
      'Atakunmosa East',
      'Aiyedaade',
      'Boluwaduro',
      'Boripe',
      'Ife East',
      'Ede South',
      'Ife North',
      'Ede North',
      'Ife South',
      'Ejigbo',
      'Ife Central',
      'Ifedayo',
      'Egbedore',
      'Ila',
      'Ifelodun',
      'Ilesa East',
      'Ilesa West',
      'Irepodun',
      'Irewole',
      'Isokan',
      'Iwo',
      'Obokun',
      'Odo Otin',
      'Ola Oluwa',
      'Olorunda',
      'Oriade',
      'Orolu',
      'Osogbo',
    ],
  },
  {
    state: 'Sokoto',
    alias: 'sokoto',
    lgas: [
      'Gudu',
      'Gwadabawa',
      'Illela',
      'Isa',
      'Kebbe',
      'Kware',
      'Rabah',
      'Sabon Birni',
      'Shagari',
      'Silame',
      'Sokoto North',
      'Sokoto South',
      'Tambuwal',
      'Tangaza',
      'Tureta',
      'Wamako',
      'Wurno',
      'Yabo',
      'Binji',
      'Bodinga',
      'Dange Shuni',
      'Goronyo',
      'Gada',
    ],
  },
  {
    state: 'Plateau',
    alias: 'plateau',
    lgas: [
      'Bokkos',
      'Barkin Ladi',
      'Bassa',
      'Jos East',
      'Jos North',
      'Jos South',
      'Kanam',
      'Kanke',
      'Langtang South',
      'Langtang North',
      'Mangu',
      'Mikang',
      'Pankshin',
      "Qua'an Pan",
      'Riyom',
      'Shendam',
      'Wase',
    ],
  },
  {
    state: 'Taraba',
    alias: 'taraba',
    lgas: [
      'Ardo Kola',
      'Bali',
      'Donga',
      'Gashaka',
      'Gassol',
      'Ibi',
      'Jalingo',
      'Karim Lamido',
      'Kumi',
      'Lau',
      'Sardauna',
      'Takum',
      'Ussa',
      'Wukari',
      'Yorro',
      'Zing',
    ],
  },
  {
    state: 'Yobe',
    alias: 'yobe',
    lgas: [
      'Bade',
      'Bursari',
      'Damaturu',
      'Fika',
      'Fune',
      'Geidam',
      'Gujba',
      'Gulani',
      'Jakusko',
      'Karasuwa',
      'Machina',
      'Nangere',
      'Nguru',
      'Potiskum',
      'Tarmuwa',
      'Yunusari',
      'Yusufari',
    ],
  },
  {
    state: 'Zamfara',
    alias: 'zamfara',
    lgas: [
      'Anka',
      'Birnin Magaji/Kiyaw',
      'Bakura',
      'Bukkuyum',
      'Bungudu',
      'Gummi',
      'Gusau',
      'Kaura Namoda',
      'Maradun',
      'Shinkafi',
      'Maru',
      'Talata Mafara',
      'Tsafe',
      'Zurmi',
    ],
  },
  {
    state: 'Lagos',
    alias: 'lagos',
    lgas: [
      'Agege',
      'Ajeromi-Ifelodun',
      'Alimosho',
      'Amuwo-Odofin',
      'Badagry',
      'Apapa',
      'Epe',
      'Eti Osa',
      'Ibeju-Lekki',
      'Ifako-Ijaiye',
      'Ikeja',
      'Ikorodu',
      'Kosofe',
      'Lagos Island',
      'Mushin',
      'Lagos Mainland',
      'Ojo',
      'Oshodi-Isolo',
      'Shomolu',
      'Surulere Lagos State',
    ],
  },
  {
    state: 'Katsina',
    alias: 'katsina',
    lgas: [
      'Bakori',
      'Batagarawa',
      'Batsari',
      'Baure',
      'Bindawa',
      'Charanchi',
      'Danja',
      'Dandume',
      'Dan Musa',
      'Daura',
      'Dutsi',
      'Dutsin Ma',
      'Faskari',
      'Funtua',
      'Ingawa',
      'Jibia',
      'Kafur',
      'Kaita',
      'Kankara',
      'Kankia',
      'Katsina',
      'Kurfi',
      'Kusada',
      "Mai'Adua",
      'Malumfashi',
      'Mani',
      'Mashi',
      'Matazu',
      'Musawa',
      'Rimi',
      'Sabuwa',
      'Safana',
      'Sandamu',
      'Zango',
    ],
  },
  {
    state: 'Kwara',
    alias: 'kwara',
    lgas: [
      'Asa',
      'Baruten',
      'Edu',
      'Ilorin East',
      'Ifelodun',
      'Ilorin South',
      'Ekiti Kwara State',
      'Ilorin West',
      'Irepodun',
      'Isin',
      'Kaiama',
      'Moro',
      'Offa',
      'Oke Ero',
      'Oyun',
      'Pategi',
    ],
  },
  {
    state: 'Nasarawa',
    alias: 'nasarawa',
    lgas: [
      'Akwanga',
      'Awe',
      'Doma',
      'Karu',
      'Keana',
      'Keffi',
      'Lafia',
      'Kokona',
      'Nasarawa Egon',
      'Nasarawa',
      'Obi',
      'Toto',
      'Wamba',
    ],
  },
  {
    state: 'Niger',
    alias: 'niger',
    lgas: [
      'Agaie',
      'Agwara',
      'Bida',
      'Borgu',
      'Bosso',
      'Chanchaga',
      'Edati',
      'Gbako',
      'Gurara',
      'Katcha',
      'Kontagora',
      'Lapai',
      'Lavun',
      'Mariga',
      'Magama',
      'Mokwa',
      'Mashegu',
      'Moya',
      'Paikoro',
      'Rafi',
      'Rijau',
      'Shiroro',
      'Suleja',
      'Tafa',
      'Wushishi',
    ],
  },
  {
    state: 'Abia',
    alias: 'abia',
    lgas: [
      'Aba North',
      'Arochukwu',
      'Aba South',
      'Bende',
      'Isiala Ngwa North',
      'Ikwuano',
      'Isiala Ngwa South',
      'Isuikwuato',
      'Obi Ngwa',
      'Ohafia',
      'Osisioma',
      'Ugwunagbo',
      'Ukwa East',
      'Ukwa West',
      'Umuahia North',
      'Umuahia South',
      'Umu Nneochi',
    ],
  },
];

export const providerData = {
  name: 'Olumide Williams',
  bio: 'Medical Doctor, Dentistry, Partnership at Duchess',
  address: 'San Francisco Bay Area, United State',
  rating: 3.6,
  accountType: '',
  reviewsCount: 42,
  professionalSummary:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident ',
  education: ['MBBS', 'MBA', 'Public Health'],
  practiseArea: ['Dentistry', 'Surgery', 'Sexual Health'],
  procedures: ['Endoscopy', 'Appendectomy'],
  insurance: ['Reliance', 'Hygeia'],
  awards: ['Best Dentist, 2022'],
  location: [0.33245, 2.3455],
  reviews: [
    {
      name: 'Mohammed Bright',
      image: '',
      date: 'November 11, 2022',
      rating: 3.0,
      comment:
        'Their service is ok but they need to improve on the pace at which we are been attended to',
      likes: 20,
      dislikes: 4,
    },
    {
      name: 'Ramon Olatunji',
      image: '',
      date: 'November 11, 2022',
      rating: 3.0,
      comment:
        'Their service is ok but they need to improve on the pace at which we are been attended to',
      likes: 20,
      dislikes: 4,
    },
    {
      name: 'Anonymous',
      image: '',
      date: 'November 11, 2022',
      rating: 3.0,
      comment:
        'Their service is ok but they need to improve on the pace at which we are been attended to',
      likes: 20,
      dislikes: 4,
    },
  ],
};

export async function getUserLocation() {
  const position = await new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject);
  });

  return position;
}

export interface ILocation {
  timestamp: number;
  coords: {
    accuracy?: number;
    altitude?: number;
    altitudeAccuracy?: number;
    heading?: number;
    latitude?: number;
    longitude?: number;
    speed?: number;
  };
}
