import { Navigate, useLocation } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks/useAppSelector';

const PublicRoute = ({ children }) => {
  const accessToken = useAppSelector((state) => state.user.currentUser?.accessToken);
  const userType = useAppSelector((state) => state.user.currentUser?.userType);
  const fromLocation = useAppSelector((state) => state.user.fromLocation);
  const isAdmin = userType === 'ADMIN';
  const isFacilityAdmin = userType === 'FACILITY_ADMIN';
  const isProvider = userType === 'PROVIDER';
  const location = useLocation();

  const auth = !!accessToken;
  const from = fromLocation ? fromLocation : location?.state?.from;

  return auth ? (
    <Navigate
      state={{ state: location?.state }}
      to={
        from
          ? from
          : isAdmin
          ? '/admin/dashboard'
          : isFacilityAdmin
          ? '/facility/dashboard'
          : isProvider
          ? '/provider/dashboard'
          : '/user/dashboard'
      }
    />
  ) : (
    children
  );
};

export default PublicRoute;
