import RefreshIcon from '@mui/icons-material/Refresh';
import { styled } from '@mui/material/styles';
import { lighten } from 'polished';
import './primaryButton.css';
import { CircularProgress } from '@mui/material';

interface IProps {
  isLoading?: boolean;
  children?: any;
  onClick?: any;
  className?: string;
  backgroundColor?: string;
  color?: string;
  width?: string;
  height?: string;
  border?: string;
  styles?: any;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  padding?: string;
  boxShadow?: string;
  fontWeight?: string;
}

const StyledButton = styled('button')<IProps>((props) => ({
  // ✅ typed-safe
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: props.width || 'auto',
  padding: '1rem ',
  border: props.border || 'none',
  borderRadius: '0.3125rem',
  boxShadow: props.boxShadow,
  color: props.color || 'white',
  fontWeight: props.fontWeight || '400',
  fontSize: '14px',
  textTransform: 'capitalize',
  overflow: 'hidden',
  minHeight: '40px',
  height: props.height || '40px',
  '&:before': {
    content: "' '",
    display: 'block',
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: 0,
    right: 0,
    backgroundColor: props.disabled
      ? '#999 !important'
      : props.backgroundColor || '#4ABE82 !important',
    zIndex: props.isLoading ? -2 : 0,
  },
  '&:after': props.isLoading
    ? {
        content: "''",
        display: 'block',
        position: 'absolute',
        height: '100%',
        width: '100%',
        top: 0,
        right: 0,
        backgroundColor: props.backgroundColor ? lighten('0.3', props.backgroundColor) : '#2ecc71',
        // backgroundColor: 'red',
        animation: 'loading 1.2s ease-in-out alternate both',
        zIndex: -1,
      }
    : {},
}));

const Button = ({
  isLoading,
  children,
  styles,
  onClick,
  className,
  width,
  type,
  color,
  disabled,
  backgroundColor,
  boxShadow,
  fontWeight,
  border,
}: IProps) => {
  return (
    <>
      <StyledButton
        isLoading={isLoading}
        style={{
          width,
          boxShadow,
          fontWeight,
          color,
          ...styles,
        }}
        onClick={() => {
          if (!isLoading) {
            onClick?.();
          }
        }}
        type={type}
        disabled={disabled}
        backgroundColor={backgroundColor}
        color={color}
        border={border}
        className={`${className}flex items-center transition ease-in-out duration-500 delay-50 hover:-translate-y-1 hover:scale-110`}
      >
        <span className={isLoading ? 'hidden' : 'z-10'}>{children}</span>
        {isLoading === true && <CircularProgress color="inherit" size={20} />}
      </StyledButton>
    </>
  );
};

export default Button;
