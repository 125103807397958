import { CombinedState, combineReducers, Reducer } from 'redux';
import { PersistPartial } from 'redux-persist/lib/persistReducer';
import locationReducer, { ILocationState } from './location/reducer';
import userReducer, { IAppUserState } from './user/reducer';

const rootReducer: Reducer<
  CombinedState<{
    user: IAppUserState & PersistPartial;
    location: ILocationState & PersistPartial;
  }>
> = combineReducers({
  user: userReducer,
  location: locationReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
