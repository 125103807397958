import storage from 'localforage';
import { persistReducer } from 'redux-persist';
import LocationTypes from './types';

export type ILocationState = {
  latitude: number;
  longitude: number;
} | null;

export const INITIAL_LOCATION_STATE = null;

function locationReducer(state: ILocationState = INITIAL_LOCATION_STATE, { type, payload }) {
  switch (type) {
    case LocationTypes.GET_USER_LOCATION:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
}

const persistConfig = {
  key: 'location',
  storage,
};

export default persistReducer(persistConfig, locationReducer);
