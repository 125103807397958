import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
interface RedirectProps {
  to: string;
  paramKey?: string;
}
const RedirectComponent: React.FC<RedirectProps> = ({ to, paramKey }) => {
  const params = useParams();
  const paramValue = paramKey ? params[paramKey] : null;
  const url = paramValue ? `${to}${paramValue}` : to;
  return <Navigate to={url} replace />;
};

export default RedirectComponent;
